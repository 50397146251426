/**
 * AnimatedBackdrop
 *
 * @selector [data-js="AnimatedBackdrop"]
 * @enabled true
 */
import createSpring from 'lemonade-spring';
import fastdom from 'fastdom';
import { base } from 'app/util/base';
import { resizeeventsManager } from 'app/util/events-manager';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function AnimatedBackdrop() {
	// Private vars
	const instance = {};
	let width = 1;
	let height = 1;
	let frameHandle = null;
	let coords = [0, 0];
	let springMeasureEvent = false;

	const stopLoop = () => {
		if (frameHandle) {
			window.cancelAnimationFrame(frameHandle);
			frameHandle = null;
		}
	};

	let spring = createSpring(coords, {
		mass: 1,
		stiffness: 0.05,
		damping: 0.8,
		precision: 0.5,
		onComplete: stopLoop,
	});

	const loop = () => {
		if (frameHandle) {
			frameHandle = window.requestAnimationFrame(loop);
		}
		spring.update();
		instance.el.style.setProperty('--bg-x', 1 - coords[0] / width);
		instance.el.style.setProperty('--bg-y', 1 - coords[1] / height);
	};

	const measure = () => {
		const rect = instance.el.getBoundingClientRect();
		width = rect.width;
		height = rect.height;
	};

	const updateSpring = () => {
		spring.target([springMeasureEvent.offsetX, springMeasureEvent.offsetY]);
		springMeasureEvent = false;
		if (!frameHandle) {
			frameHandle = window.requestAnimationFrame(loop);
		}
	};

	const onMouseMove = (e) => {
		if (!springMeasureEvent) {
			springMeasureEvent = e;
			fastdom.measure(updateSpring);
		}
	};

	// Private methods
	const bindEvents = () => {
		instance.el.addEventListener('mousemove', onMouseMove);
		resizeeventsManager.add(measure);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('mousemove', onMouseMove);
		resizeeventsManager.remove(measure);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);

		bindEvents();
		fastdom.measure(measure);

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
		instance.el = null;
	};

	return instance;
}
