/**
 * CookieBanner
 *
 * @selector [data-js="CookieBanner"]
 * @enabled true
 */
import { base } from 'app/util/base';

const config = {
	optionsAttr: 'data-options',
};

export default function CookieBanner() {
	// Private vars
	const instance = {};
	let settings = {
		cookieBannerAcceptedDays: 365,
	};
	let confirmationButton = null;

	const handleConfirmationButtonClick = () => {
		const timestamp = new Date().getTime();
		localStorage.setItem('cookieBannerAccepted', timestamp);
		instance.el.classList.add(settings.hiddenClass);
	};

	const bindEvents = () => {
		confirmationButton.addEventListener('click', handleConfirmationButtonClick);
	};

	const unbindEvents = () => {
		confirmationButton.removeEventListener(
			'click',
			handleConfirmationButtonClick
		);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, options);

		confirmationButton = instance.el.querySelector(
			`.${settings.confirmationButtonClass}`
		);

		bindEvents();

		// Check if cookie banner has been accepted
		const timestampCookieBannerAccepted = localStorage.getItem(
			'cookieBannerAccepted'
		);
		if (timestampCookieBannerAccepted) {
			const timestamp = new Date().getTime();
			const diff = timestamp - timestampCookieBannerAccepted;
			const days = Math.floor(diff / (1000 * 60 * 60 * 24));

			if (days > settings.cookieBannerAcceptedDays) {
				instance.el.classList.remove(settings.hiddenClass);
			}
		} else {
			instance.el.classList.remove(settings.hiddenClass);
		}

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
