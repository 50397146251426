/**
 * Header
 *
 * @selector [data-js="Header"]
 * @enabled true
 */
import { base } from 'app/util/base';
import messageBus from 'app/util/message-bus';

const config = {
	optionsAttr: 'data-options',
};

export default function Header() {
	// Private vars
	const instance = {};
	let settings = {};
	let menuTrigger = null;
	let menuBackground = null;
	let menuOpen = false;

	const handleMenuToggle = () => {
		if (menuOpen) {
			instance.el.classList.remove(settings.menuActiveClass);
			menuOpen = false;
		} else {
			instance.el.classList.add(settings.menuActiveClass);
			menuOpen = true;
		}
	};

	// Private methods
	const handleSearchFocus = (isFocused) => {
		if (isFocused) {
			instance.el.classList.add('has-search');
		} else {
			instance.el.classList.remove('has-search');
		}
	};

	const handleProfileButtonToggle = (active) => {
		if (active) {
			instance.el.classList.add('has-backdrop');
		} else {
			instance.el.classList.remove('has-backdrop');
		}
	};

	const bindEvents = () => {
		messageBus.on('SearchBar:focus', handleSearchFocus);
		messageBus.on('ProfileButton:toggle', handleProfileButtonToggle);
		menuTrigger.addEventListener('click', handleMenuToggle);
		menuBackground.addEventListener('click', handleMenuToggle);
	};

	const unbindEvents = () => {
		messageBus.removeListener('SearchBar:focus', handleSearchFocus);
		messageBus.removeListener(
			'ProfileButton:toggle',
			handleProfileButtonToggle
		);
		menuTrigger.removeEventListener('click', handleMenuToggle);
		menuBackground.removeEventListener('click', handleMenuToggle);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, options);

		menuTrigger = instance.el.querySelector(`.${settings.menuTriggerClass}`);
		menuBackground = instance.el.querySelector(
			`.${settings.menuBackgroundClass}`
		);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
