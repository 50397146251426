/**
 * CardExpander
 *
 * @selector [data-js="CardExpander"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function CardExpander() {
	// Private vars
	const instance = {};
	let settings = {};
	let carListWrapper = null;
	let cardList = null;
	let trigger = null;
	let isExpanded = false;

	const animationDuration = 500; // Align with CSS transition duration (see .CardExpander--list-wrapper)

	// Private methods
	const bindEvents = () => {
		trigger.addEventListener('click', handleTriggerClick);
	};

	const unbindEvents = () => {
		trigger.removeEventListener('click', handleTriggerClick);
	};

	const handleTriggerClick = () => {
		if (isExpanded) {
			collapse();
		} else {
			expand();
		}
	};

	const expand = () => {
		carListWrapper.style.height = `${getContainerHeight()}px`;
		cardList.classList.add(settings.cardExpanderListClassOpen);
		trigger.innerHTML = settings.labelLess;
		trigger.setAttribute('aria-expanded', true);
		isExpanded = true;

		setTimeout(() => {
			carListWrapper.style.height = `${getContainerHeight(true)}px`;
		});

		setTimeout(() => {
			carListWrapper.removeAttribute('style');
		}, animationDuration);
	};

	const collapse = () => {
		carListWrapper.style.height = `${getContainerHeight(true)}px`;
		trigger.innerHTML = settings.labelMore;
		trigger.setAttribute('aria-expanded', false);
		isExpanded = false;

		setTimeout(() => {
			carListWrapper.style.height = `${getContainerHeight()}px`;
		});

		setTimeout(() => {
			carListWrapper.removeAttribute('style');
			cardList.classList.remove(settings.cardExpanderListClassOpen);
		}, animationDuration);
	};

	const getContainerHeight = (whenOpen = false) => {
		const elements = Array.from(carListWrapper.querySelectorAll('li'));
		const lastVisibleElement = whenOpen
			? elements[elements.length - 1]
			: elements[settings.numInitialItems - 1];
		return lastVisibleElement.offsetTop + lastVisibleElement.offsetHeight;
	};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, defaults, options);

		// Get DOM elements
		carListWrapper = instance.el.querySelector(
			`#${settings.cardExpanderListWrapperId}`
		);
		cardList = instance.el.querySelector(`#${settings.cardExpanderListId}`);
		trigger = instance.el.querySelector(`#${settings.moreLessTriggerId}`);

		if (!carListWrapper || !cardList || !trigger) {
			return;
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
