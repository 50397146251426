/**
 * ShareAction
 *
 * @selector [data-js="ShareAction"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {
	title: 'Share',
	url: null,
	text: null,
};

const config = {
	optionsAttr: 'data-options',
};

export default function ShareAction() {
	// Private vars
	const instance = {};
	let settings,
		toggle,
		checkbox = {},
		canShare = true;

	// Private methods
	const handleOutsideClick = (evt) => {
		if (!evt.target.closest('.ShareAction')) {
			checkbox.checked = false;
			document.removeEventListener('click', handleOutsideClick);
		}
	};

	const handleClick = (evt) => {
		if (canShare && navigator.share) {
			try {
				navigator
					.share({
						url: settings.url,
						title: settings.title,
						text: settings.text,
					})
					.catch((err) => {
						if (err.name !== 'AbortError') {
							canShare = false;
							checkbox.checked = !checkbox.checked;
						}
						console.warn('Unable to share', err.name);
					});

				evt.preventDefault();
				evt.stopPropagation();
			} catch (e) {
				console.warn('Unable to share', e);
			}
		}
	};

	const handleChange = (evt) => {
		if (evt.currentTarget.checked) {
			document.addEventListener('click', handleOutsideClick);
		} else {
			document.removeEventListener('click', handleOutsideClick);
		}
	};

	const bindEvents = () => {
		toggle.addEventListener('click', handleClick);
		checkbox.addEventListener('change', handleChange);
		//document.addEventListener('click', handleOutsideClick);
	};

	const unbindEvents = () => {
		toggle.removeEventListener('click', handleClick);
		document.removeEventListener('click', handleOutsideClick);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		toggle = instance.ref('toggle');
		checkbox = instance.ref('checkbox');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
