/**
 * TagList
 *
 * @selector [data-js="TagList"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { resizeeventsManager } from 'app/util/events-manager';

const defaults = {
	isCompact: false,
	maxLines: 2,
};

const config = {
	optionsAttr: 'data-options',
};

export default function TagList() {
	// Private vars
	const instance = {};
	let settings = {};
	let tags, placeholder;

	const repaint = () => {
		if (tags && tags.length) {
			tags.forEach((tag) => tag.classList.remove('hidden'));
			placeholder.classList.remove('hidden');

			let hidden = 0;
			let firstRect = tags[0].getBoundingClientRect();
			let lastRect = tags[tags.length - 1].getBoundingClientRect();
			let yThreshold =
				firstRect.top + firstRect.height * (settings.maxLines - 0.5);

			// If the last tag exceeds the threshold (eg. is on a line > maxLines), then
			// iteratively hide tags until the placeholder in within the threshold
			if (lastRect.top > yThreshold) {
				let r = placeholder.getBoundingClientRect();
				while (r.top > yThreshold && hidden < tags.length) {
					tags[tags.length - ++hidden].classList.add('hidden');
					placeholder.querySelector('.Tag--label').textContent = `+${hidden}`;
					r = placeholder.getBoundingClientRect();
				}
			}

			if (hidden > 0) {
				placeholder.classList.remove('hidden');
			} else {
				placeholder.classList.add('hidden');
			}
		}
	};

	const handlePlaceholderClick = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		unbindEvents();
		placeholder.parentNode.removeChild(placeholder);
		placeholder = null;
		instance.el.classList.add('show-all');
	};

	const bindEvents = () => {
		resizeeventsManager.add(repaint);
		if (placeholder) {
			placeholder
				.querySelector('.Tag')
				.addEventListener('click', handlePlaceholderClick);
		}
	};

	const unbindEvents = () => {
		resizeeventsManager.remove(repaint);
		if (placeholder) {
			placeholder
				.querySelector('.Tag')
				.removeEventListener('click', handlePlaceholderClick);
		}
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		tags = instance.ref('tag');

		if (settings.isCompact && tags.length) {
			placeholder = tags[0].cloneNode(true);
			tags[0].parentNode.appendChild(placeholder);
			bindEvents();
			repaint();

			if (document.fonts) {
				document.fonts.ready.then(repaint);
			}
		}

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
