/**
 * Slider
 *
 * @selector [data-js="Slider"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {
	slidesPerView: 'auto',
	prevNextButtons: true,
	pagination: true,
	showCaptions: false,
	mobileReadmore: false,
	controls: false,
	effect: false,
	speed: 500,
};

const config = {
	optionsAttr: 'data-options',
};

const MOBILE_BP = '(max-width: 750px)';

// Breakpoint settings for a 3 slide default
const BP_3_SLIDES = {
	breakpoints: {
		0: {
			slidesPerView: 'auto',
			slidesPerGroup: 1,
		},
		1001: {
			slidesPerView: 3,
			slidesPerGroup: 3,
		},
	},
};

// Breakpoint settings for a 2 slide default
const BP_2_SLIDES = {
	breakpoints: {
		0: {
			slidesPerView: 'auto',
			slidesPerGroup: 1,
		},
		1001: {
			slidesPerView: 2,
			slidesPerGroup: 2,
		},
		1800: {
			slidesPerView: 3,
			slidesPerGroup: 3,
		},
	},
};

let Swiper;

export default function Slider() {
	// Private vars
	const instance = {};
	let settings = {};
	let swiperInstance,
		prev,
		next,
		caption,
		gap,
		readMore,
		matcher,
		controlsNode,
		controls;

	const handleResize = () => {
		swiperInstance.params.spaceBetween = gap.clientWidth;
		swiperInstance.update();
	};

	const handleNext = () => {
		if (swiperInstance) {
			let offset =
				typeof swiperInstance.params.slidesPerView === 'number'
					? swiperInstance.params.slidesPerView
					: 1;
			let target = Math.min(
				swiperInstance.slides.length - 1,
				swiperInstance.activeIndex + offset
			);
			swiperInstance.slideTo(
				target,
				swiperInstance.params.speed *
					Math.max(1, (target - swiperInstance.activeIndex) * 0.66666)
			);
		}
	};

	const handlePrev = () => {
		if (swiperInstance) {
			let offset =
				typeof swiperInstance.params.slidesPerView === 'number'
					? swiperInstance.params.slidesPerView
					: 1;
			let target = Math.max(0, swiperInstance.activeIndex - offset);
			if (offset > 1) {
				target = Math.max(0, swiperInstance.activeIndex - offset + 1);
			}
			swiperInstance.slideTo(
				target,
				swiperInstance.params.speed *
					Math.max(1, (swiperInstance.activeIndex - target) * 0.66666)
			);
		}
	};

	const updateButtons = () => {
		if (prev) {
			if (swiperInstance.isBeginning) {
				prev.classList.add('disabled');
			} else {
				prev.classList.remove('disabled');
			}
		}

		if (next) {
			if (swiperInstance.isEnd) {
				next.classList.add('disabled');
			} else {
				next.classList.remove('disabled');
			}
		}
	};

	const updateCaption = () => {
		const slide = swiperInstance.slides[swiperInstance.activeIndex];
		const slideCaption = slide.querySelector('.slide-caption');
		caption.innerHTML = slideCaption.innerHTML;
	};

	const handleReadmore = () => {
		let inner = readMore.querySelector('.Button--text');
		if (instance.el.classList.contains('show-more')) {
			instance.el.classList.remove('show-more');
			inner.innerText = readMore.dataset.textMore || 'Read more';
		} else {
			instance.el.classList.add('show-more');
			inner.innerText = readMore.dataset.textLess || 'Less';
		}
	};

	const handleMobileChange = (evt) => {
		if (evt.matches) {
			destroySwiper();
		} else {
			initSwiper();
		}
	};

	const bindEvents = () => {
		if (prev) {
			prev.addEventListener('click', handlePrev);
		}
		if (next) {
			next.addEventListener('click', handleNext);
		}
		if (readMore) {
			readMore.addEventListener('click', handleReadmore);
			matcher.addListener(handleMobileChange);
		}

		if (controlsNode) {
			controlsNode.addEventListener('swiperInit', initControlled);
		}
	};

	const unbindEvents = () => {
		if (prev) {
			prev.removeEventListener('click', handlePrev);
		}
		if (next) {
			next.removeEventListener('click', handleNext);
		}
		if (readMore) {
			readMore.removeEventListener('click', handleReadmore);
			matcher.removeListener(handleMobileChange);
		}
		if (controlsNode) {
			controlsNode.removeEventListener('swiperInit', initControlled);
		}
	};

	const destroySwiper = () => {
		if (swiperInstance) {
			swiperInstance.destroy();
			swiperInstance = null;
		}
	};

	// Dispatch swiper init via the Slider DOM Node
	function dispatchInit() {
		const updateEvent = new CustomEvent('swiperInit', {
			bubbles: true,
			detail: this,
		});

		instance.el.dispatchEvent(updateEvent);
	}

	const initControlled = (evt) => {
		controls = evt.detail;
		initialize();
	};

	const initSwiper = () => {
		if (!Swiper) {
			// Load swiper if not present, can be the case when starting on mobile
			import(/* webpackChunkName: "SWIPER" */ 'app/swiper/swiper').then(
				initialize
			);
			return;
		}

		if (swiperInstance) {
			destroySwiper();
		}

		let swiperOpts = {
			slidesPerView: settings.slidesPerView,
			slidesPerGroup: settings.slidesPerView,
			spaceBetween: 30,
			speed: settings.speed,
			on: {
				resize: handleResize,
				init: dispatchInit,
			},
		};

		if (settings.effect) {
			Object.assign(swiperOpts, {
				effect: settings.effect,
			});
		}

		if (controls) {
			Object.assign(swiperOpts, {
				controller: {
					control: controls,
				},
			});
		}

		if (settings.slidesPerView === 3) {
			Object.assign(swiperOpts, BP_3_SLIDES);
		} else if (settings.slidesPerView === 2) {
			Object.assign(swiperOpts, BP_2_SLIDES);
		} else if (settings.slidesPerView === 'mobile-centered') {
			Object.assign(swiperOpts, {
				breakpoints: {
					0: {
						slidesPerView: 'auto',
						slidesPerGroup: 1,
						centeredSlides: true,
					},
					750: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						centeredSlides: false,
					},
				},
			});
		}

		if (settings.pagination) {
			Object.assign(swiperOpts, {
				pagination: {
					el: instance.ref('pagination'),
					type: 'custom',
					renderCustom: (swiper, current, total) => {
						if (swiper.slidesPerGroup > 1) {
							total = Math.ceil(swiper.slides.length / swiper.slidesPerView);
							current =
								1 + Math.ceil(swiper.activeIndex / swiper.slidesPerView);
						}
						return `<span class="Slider--pagination-current">${current}</span><span class="visuallyhidden">/</span><span class="Slider--pagination-total">${total}</span>`;
					},
				},
			});
		}

		swiperInstance = new Swiper(instance.ref('container'), swiperOpts);

		if (settings.prevNextButtons) {
			swiperInstance.on('slideChange', updateButtons);
			updateButtons();
		}

		if (settings.showCaptions) {
			swiperInstance.on('slideChange', updateCaption);
			updateCaption();
		}

		handleResize();
	};

	const initialize = (SwiperModule) => {
		if (!(settings.mobileReadmore && matcher.matches)) {
			if (SwiperModule) {
				Swiper = SwiperModule.default;
			}
			initSwiper();
		}
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		// Get references
		if (settings.mobileReadmore) {
			readMore = instance.ref('readmore');
			matcher = window.matchMedia(MOBILE_BP);
		}

		if (settings.prevNextButtons) {
			prev = instance.ref('prev');
			next = instance.ref('next');
		}

		if (settings.showCaptions) {
			caption = instance.ref('caption');
		}

		gap = instance.ref('gap');

		if (settings.controls) {
			controlsNode = document.querySelector(
				`[data-ref="${settings.controls}"]`
			);
		}
		if (!controlsNode) {
			initialize();
		}
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		swiperInstance.destroy();
		unbindEvents();
	};

	return instance;
}
