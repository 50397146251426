/**
 * AudioContent
 *
 * @selector [data-js="AudioContent"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function VideoEmbed() {
	// Private vars
	const instance = {};
	let button;

	const handleClick = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		unbindEvents();
		instance.el.classList.add('playing');
	};

	const bindEvents = () => {
		button.addEventListener('click', handleClick);
	};

	const unbindEvents = () => {
		button.removeEventListener('click', handleClick);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);

		button = instance.ref('button');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
