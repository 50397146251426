/**
 * FilterCard
 *
 * @selector [data-js="FilterCard"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function FilterCard() {
	// Private vars
	const instance = {};
	let settings = {};
	let trigger = null;
	let panel = null;
	let panelContent = null;

	// Private methods
	const bindEvents = () => {
		trigger?.addEventListener('click', handleExpanderClick);
	};

	const unbindEvents = () => {
		trigger?.removeEventListener('click', handleExpanderClick);
	};

	const handleExpanderClick = (event) => {
		event.preventDefault();
		const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
		if (isExpanded) {
			trigger.setAttribute('aria-expanded', 'false');
			panel.setAttribute('aria-hidden', 'true');
			panel.style.height = 0;
		} else {
			trigger.setAttribute('aria-expanded', 'true');
			panel.setAttribute('aria-hidden', 'false');
			panel.style.height = `${panelContent.offsetHeight}px`;
		}
	};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, defaults, options);

		// Get DOM elements
		trigger = instance.el.querySelector(`.${settings.triggerClass}`);
		panel = instance.el.querySelector(`.${settings.panelClass}`);
		panelContent = instance.el.querySelector(`.${settings.panelContentClass}`);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
