// Polyfills are added in the template using polyfill.io
import 'es6-symbol/implement';
import 'requestidlecallback';

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
// Test via a getter in the options object to see if the passive property is accessed
window.HV = window.HV || {};
window.HV.supportsPassive = false;
try {
	let opts = Object.defineProperty({}, 'passive', {
		get: function () {
			window.HV.supportsPassive = true;
			return true;
		},
	});
	window.addEventListener('testPassive', null, opts);
	window.removeEventListener('testPassive', null, opts);
} catch (e) {
	// No support or passive listeners. sad
}
