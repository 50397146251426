import { smoother } from './smoother';

const manager = (eventName) => {
	const instance = {};

	const listeners = [];
	let len;

	const onEvent = smoother(function () {
		for (let i = 0; i < len; i++) {
			listeners[i](...arguments);
		}
	});

	const bind = () => {
		if (window.HV.supportsPassive) {
			window.addEventListener(eventName, onEvent, { passive: true });
		} else {
			window.addEventListener(eventName, onEvent);
		}
	};

	const unbind = () => {
		window.removeEventListener(eventName, onEvent);
	};

	instance.add = (listener) => {
		for (let i = listeners.length; i--; ) {
			if (listeners[i] === listener) {
				// Don't add same listener multiple times.
				return;
			}
		}

		listeners.push(listener);
		len = listeners.length;

		if (len > 0) {
			bind();
		}
	};

	instance.remove = (listener) => {
		for (let i = len; i--; ) {
			if (listeners[i] === listener) {
				listeners.splice(i, 1);
				len = listeners.length;
				break;
			}
		}

		if (len === 0) {
			unbind();
		}
	};

	return instance;
};

export const resizeeventsManager = manager('resize');
export const scrolleventsManager = manager('scroll');
