/**
 * Search
 *
 * @selector [data-js="Search"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function Search() {
	// Private vars
	let abortController = null;
	let signal = null;
	const instance = {};
	let settings = {};
	let searchTitle = null;
	let searchTitleResults = null;
	let searchForm = null;
	let searchQueryStringContainer = null;
	let searchField = null;
	let resultsSection = null;
	let resultsContainer = null;
	let resultsHitsContainerWrapper = null;
	let resultsHitsContainer = null;
	let paginationContainer = null;
	let paginationOverviewText = null;
	let paginationButtonPrev = null;
	let paginationButtonNext = null;
	let paginationLinks = [];

	// Private methods
	const bindEvents = () => {
		bindSearchEvents();
		bindPaginationEvents();
	};

	const unbindEvents = () => {
		unbindSearchEvents();
		unbindPaginationEvents();
	};

	const bindSearchEvents = () => {
		searchForm?.addEventListener('submit', handleSearchSubmit);
	};

	const unbindSearchEvents = () => {
		searchForm?.removeEventListener('submit', handleSearchSubmit);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		if (!searchField.value) {
			return;
		}
		updateUrlWithSearchParam();
		window.history.pushState({}, '', decodeURI(new URL(event.target.action)));

		requestResults();
	};

	const updateUrlWithSearchParam = () => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search.slice(1));
		params.set('query', searchField.value ?? '');

		// Remove param offset for new search.
		params.delete('offset');

		// Update the URL.
		url.search = params.toString();
		window.history.pushState({}, '', decodeURI(url));
	};

	const sendEtrackerEvent = (query) => {
		// Working solution for etracker
		// Go to Etracker backend > Engagement Reports > Events, then select
		// below Category "Interne Suche" to see the results.
		/* eslint-disable no-undef */
		_etracker.sendEvent(
			new et_UserDefinedEvent(
				'Search', // Object name
				'Interne Suche', // Category
				query, // Action (search term)
				'Search Query' // Type
			)
		);
		/* eslint-enable no-undef */
	};

	const bindPaginationEvents = () => {
		paginationButtonPrev?.addEventListener('click', handlePaginationClick);
		paginationButtonNext?.addEventListener('click', handlePaginationClick);
		paginationLinks?.forEach((link) => {
			link.addEventListener('click', handlePaginationClick);
		});
	};

	const unbindPaginationEvents = () => {
		paginationButtonPrev?.removeEventListener('click', handlePaginationClick);
		paginationButtonNext?.removeEventListener('click', handlePaginationClick);
		paginationLinks?.forEach((link) => {
			link.removeEventListener('click', handlePaginationClick);
		});
	};

	const updatePagination = () => {
		unbindPaginationEvents();
		paginationContainer = instance.el.querySelector(
			`#${settings.paginationContainerId}`
		);
		paginationButtonPrev = instance.el.querySelector(
			`.${settings.paginationButtonPrevClass}`
		);
		paginationButtonNext = instance.el.querySelector(
			`.${settings.paginationButtonNextClass}`
		);
		paginationLinks = Array.from(
			instance.el.querySelectorAll(`.${settings.paginationButtonLinkClass}`)
		);
		bindPaginationEvents();
	};

	const handlePaginationClick = (event) => {
		event.preventDefault();
		window.history.pushState({}, '', decodeURI(new URL(event.target.href)));
		requestResults();
	};

	const requestResults = () => {
		if (abortController) {
			abortController.abort();
		}

		abortController = new AbortController();
		signal = abortController.signal;

		const currentUrl = new URL(window.location.href);
		const paramString = currentUrl.search.slice(1);
		const apiUrl = `${settings.endpoint}?${paramString}`.replace('/?', '?');

		resultsSection.hidden = false;
		instance.el.classList.add(settings.resultsLoadingClass);
		return fetch(apiUrl, {
			method: 'GET',
			signal: signal, // signal is an AbortSignal object (see AbortController above)
			headers: {
				Accept: 'application/json',
			},
		})
			.then((response) => response.json())
			.then((result) => processResult(result));
	};

	const processResult = (result) => {
		if (
			!searchTitle ||
			!searchTitleResults ||
			!resultsContainer ||
			!resultsHitsContainerWrapper ||
			!resultsHitsContainer ||
			!paginationContainer ||
			!paginationOverviewText
		) {
			return;
		}

		// Print results
		searchTitle.hidden = true;
		searchTitleResults.hidden = false;
		resultsContainer.innerHTML = result.html ?? '';
		resultsHitsContainerWrapper.hidden = result.resultsTotal === 0;
		resultsHitsContainer.innerHTML = result.resultsTotal ?? '';

		// Update pagination
		paginationContainer.innerHTML = result.pagination ?? '';
		paginationOverviewText.innerHTML = result.paginationOverviewText ?? '';

		// Rebind pagination events.
		updatePagination();

		// Update search query string
		searchQueryStringContainer.textContent = result.searchQueryString ?? '';

		// Remove loading class
		instance.el.classList.remove(settings.resultsLoadingClass);

		// Reset abort controller
		abortController = null;
		resultsSection.scrollIntoView({ behavior: 'smooth' });

		sendEtrackerEvent(searchField.value);
	};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, defaults, options);

		searchForm = instance.el.querySelector(`#${settings.searchFormId}`);
		searchQueryStringContainer = instance.el.querySelector(
			`#${settings.searchQueryStringContainerId}`
		);
		searchField = instance.el.querySelector(`#${settings.searchFieldId}`);
		searchTitle = instance.el.querySelector(`#${settings.searchTitleId}`);
		searchTitleResults = instance.el.querySelector(
			`#${settings.searchTitleResultsId}`
		);
		resultsSection = instance.el.querySelector(`#${settings.resultsSectionId}`);
		resultsContainer = instance.el.querySelector(
			`#${settings.resultsContainerId}`
		);
		resultsHitsContainerWrapper = instance.el.querySelector(
			`#${settings.resultsHitsContainerWrapperId}`
		);
		resultsHitsContainer = instance.el.querySelector(
			`#${settings.resultsHitsContainerId}`
		);
		paginationContainer = instance.el.querySelector(
			`#${settings.paginationContainerId}`
		);
		paginationOverviewText = instance.el.querySelector(
			`#${settings.paginationOverviewTextId}`
		);
		paginationButtonPrev = instance.el.querySelector(
			`.${settings.paginationButtonPrevClass}`
		);
		paginationButtonNext = instance.el.querySelector(
			`.${settings.paginationButtonNextClass}`
		);
		paginationLinks = Array.from(
			instance.el.querySelectorAll(`.${settings.paginationButtonLinkClass}`)
		);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
