/**
 * SearchBar
 *
 * @selector [data-js="SearchBar"]
 * @enabled true
 */
import { base } from 'app/util/base';
import messageBus from 'app/util/message-bus';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

const tokenPattern = /^(type:|@|#)(.*)$/i;

export default function SearchBar() {
	// Private vars
	const instance = {};
	let input,
		rendered,
		clearButton,
		currentQuery,
		renderContinuous = false,
		pendingRender = false,
		tabbedOut = false,
		blurTimeout;

	const scheduleRender = () => {
		if (pendingRender === false) {
			pendingRender = window.requestAnimationFrame(render);
		}
	};

	const startContinousRendering = () => {
		renderContinuous = true;
		scheduleRender();
	};

	const stopContinousRendering = () => {
		renderContinuous = false;
	};

	const onAddContent = (content) => {
		if (currentQuery.endsWith(' ')) {
			input.value = `${currentQuery} ${content} `;
		} else if (currentQuery.match(/\s([^\s]+?)$/)) {
			input.value = currentQuery.replace(/\s([^\s]+?)$/, ` ${content} `);
		} else {
			input.value = `${content} `;
		}

		// Clear event bindings before submitting
		unbindEvents();
		instance.ref('form').submit();
	};

	const onFocus = () => {
		tabbedOut = false;

		if (blurTimeout) {
			window.clearInterval(blurTimeout);
			blurTimeout = false;
		}

		instance.el.classList.add('focused');
		messageBus.emit('SearchBar:focus', true);
		document.addEventListener('keydown', handleKeyDown);
		scheduleRender();
	};

	const onBlur = () => {
		if (blurTimeout) {
			window.clearInterval(blurTimeout);
			blurTimeout = false;
		}
		blurTimeout = window.setTimeout(handleBlur, 200);
	};

	const handleBlur = () => {
		if (!tabbedOut) {
			instance.el.classList.remove('focused');
			messageBus.emit('SearchBar:focus', false);
			blurTimeout = false;
			document.removeEventListener('keydown', handleKeyDown);
		}
	};

	const handleKeyDown = (e) => {
		if (e.currentTarget === input) {
			tabbedOut = e.which === 9;
		} else if (e.which === 27) {
			tabbedOut = false;
			if (e.target === input) {
				input.blur();
			} else {
				handleBlur();
			}
		}
		scheduleRender();
	};

	// Handle clicks on the reset button. Fully clear the form
	const handleReset = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		document.removeEventListener('keydown', handleKeyDown);
		input.value = '';
		input.focus();
		scheduleRender();
	};

	// Parse query into tokens
	const parse = (query) => {
		const tokens = query.split(/[\s,]+/);
		const end = tokens.length - 1;
		let textQuery = [];
		return {
			tokens: tokens
				.map((token, i) => {
					const match = token.match(tokenPattern);
					if (match) {
						return {
							type: match[1].toLowerCase(),
							value: match[2],
							current: i === end,
						};
					} else {
						textQuery.push(token);
						return {
							type: 'text',
							value: token,
							current: i === end,
						};
					}
				})
				.filter((token) => token.type !== 'text' || token.value !== ''),
			plain: tokens.join(' '),
			textQuery: textQuery.join(' '),
		};
	};

	const render = () => {
		pendingRender = false;
		if (currentQuery !== input.value) {
			const result = parse(input.value);
			input.value = currentQuery = result.plain;
			console.log(result);
			rendered.innerHTML = result.tokens
				.map((token) => {
					if (token.type === 'text') {
						return token.value;
					}

					return `<em>${token.type}</em>${token.value.replace(
						/([_]+)/gi,
						'<em>$1</em>'
					)}`;
				})
				.join(' ');

			messageBus.emit('SearchBar:change', result);
		}

		rendered.style.textIndent = `-${input.scrollLeft}px`;

		if (renderContinuous) {
			scheduleRender();
		}
	};

	// Private methods
	const bindEvents = () => {
		input.addEventListener('change', scheduleRender);
		input.addEventListener('keydown', handleKeyDown);
		input.addEventListener('keyup', scheduleRender);
		input.addEventListener('select', scheduleRender);
		input.addEventListener('focus', onFocus);
		input.addEventListener('blur', onBlur);
		// Continous rendering is required when user draws a selection that scrolls
		input.addEventListener('mousedown', startContinousRendering);
		input.addEventListener('mouseup', stopContinousRendering);
		clearButton.addEventListener('click', handleReset);
		messageBus.on('SearchBar:addContent', onAddContent);
	};

	const unbindEvents = () => {
		input.removeEventListener('change', scheduleRender);
		input.removeEventListener('keydown', handleKeyDown);
		input.removeEventListener('keyup', scheduleRender);
		input.removeEventListener('select', scheduleRender);
		input.removeEventListener('focus', onFocus);
		input.removeEventListener('blur', onBlur);

		input.removeEventListener('mousedown', startContinousRendering);
		input.removeEventListener('mouseup', stopContinousRendering);
		clearButton.removeEventListener('click', handleReset);
		messageBus.removeListener('SearchBar:addContent', onAddContent);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		input = instance.ref('input');
		rendered = instance.ref('rendered');
		clearButton = instance.ref('clearButton');
		bindEvents();
		scheduleRender();

		return instance;
	};

	instance.destroy = () => {
		renderContinuous = false;
		if (pendingRender) {
			window.cancelAnimationFrame(pendingRender);
		}

		if (blurTimeout) {
			window.clearInterval(blurTimeout);
			blurTimeout = false;
		}

		unbindEvents();
	};

	return instance;
}
