/**
 * SearchPresets
 *
 * @selector [data-js="SearchPresets"]
 * @enabled true
 */
import { base } from 'app/util/base';
import messageBus from 'app/util/message-bus';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function SearchPresets() {
	// Private vars
	const instance = {};

	// Private methods
	const handleChange = (result) => {
		if (result.tokens.length) {
			instance.el.classList.add('disabled');
		} else {
			instance.el.classList.remove('disabled');
		}
	};

	const bindEvents = () => {
		messageBus.on('SearchBar:change', handleChange);
	};

	const unbindEvents = () => {
		messageBus.removeListener('SearchBar:change', handleChange);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
