const base = (instance) => {
	instance.ns = instance.el.getAttribute('data-js');

	instance.hasState = (state) =>
		instance.el.getAttribute('data-state') === state;
	instance.getState = () => instance.el.getAttribute('data-state');
	instance.setState = (state) => {
		instance.el.setAttribute('data-state', state);
	};

	/**
	 * @function ref
	 *
	 * @param {string} name
	 * @param {boolean} [asArray=false] asArray - Always return array even if only one element is found
	 * @returns {Array|Element|boolean} Return the element itsekf if only one is found. Else return elements in an array.
	 */
	instance.ref = (name, asArray = false) => {
		if (!instance.el || !(instance.el instanceof HTMLElement)) {
			throw new Error(`Container element is not a DOM node.`);
		}

		const refEls = instance.el.querySelectorAll(instance.refSelector(name));

		if (refEls.length === 0) {
			return false;
		}

		if (refEls.length === 1 && asArray === false) {
			return refEls[0];
		}

		return Array.from(refEls);
	};

	/**
	 * @function refSelector
	 *
	 * Get CSS selector for ref.
	 * Just pass the ref name without the namespace.
	 *
	 * @param {string} name
	 * @returns {string}
	 */
	instance.refSelector = (name) => `[data-ref="${instance.ns}:${name}"]`;

	return instance;
};

export { base };
