/**
 * AnimateReveal
 *
 * This registers all elements with the `data-animate` attribute with the reveal-manager.
 *
 * @selector [data-animate]
 * @enabled true
 */
import { base } from 'app/util/base';
import { revealHandler } from 'app/util/reveal';

const defaults = {};

const config = {
	optionsAttr: 'data-animate',
};

export default function AnimateReveal() {
	// Private vars
	const instance = {};
	let settings = {};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		const attrData = instance.el.getAttribute(config.optionsAttr);
		if (attrData) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		revealHandler.register(element, settings);

		return instance;
	};

	instance.destroy = () => {
		revealHandler.unregister(instance.el);
		instance.el = null;
		settings = null;
	};

	return instance;
}
