/**
 * ScrollTopAction
 *
 * @selector [data-js="ScrollTopAction"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function ScrollTopAction() {
	// Private vars
	const instance = {};
	let button;

	// Private methods
	const handleClick = (evt) => {
		if (typeof button.scrollTo === 'function') {
			evt.stopPropagation();
			evt.preventDefault();
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	const calcPageOffset = () => {
		const shouldBeVisible = window.pageYOffset > 150;

		if (instance.visible !== shouldBeVisible) {
			instance.visible = shouldBeVisible;
			instance.el.classList.toggle('is-visible', shouldBeVisible);
		}
	};

	const bindEvents = () => {
		window.addEventListener('scroll', calcPageOffset);

		if (button) {
			button.addEventListener('click', handleClick);
		}
	};

	const unbindEvents = () => {
		window.removeEventListener('scroll', calcPageOffset);

		if (button) {
			button.removeEventListener('click', handleClick);
		}
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		instance.visible = false;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		button = instance.ref('button');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
		button = null;
	};

	return instance;
}
