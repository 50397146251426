/**
 * Input
 *
 * @selector [data-js="Input"]
 * @enabled true
 */

import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function Input() {
	// Private vars
	const instance = {};
	let tooltip;

	const handleTooltipClick = (evt) => {
		if (document.documentElement.classList.contains('touch')) {
			evt.preventDefault();
			alert(tooltip.getAttribute('title'));
		}
	};

	// Private methods
	const bindEvents = () => {
		if (tooltip) {
			tooltip.addEventListener('click', handleTooltipClick);
		}
	};

	const unbindEvents = () => {
		if (tooltip) {
			tooltip.removeEventListener('click', handleTooltipClick);
		}
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		tooltip = instance.ref('tooltip');
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
