/**
 * Form
 *
 * @selector [data-js="Form"]
 * @enabled true
 */
import { base } from 'app/util/base';
const defaults = {};

const config = {
	optionsAttr: 'data-options',
	formRef: 'form',
	inputFieldRef: 'input',
	submitBtn: 'submitBtn',
	submitTimeout: 30 * 1000,
	forcedSubmitDelay: 500,
};

export default function ContactForm() {
	// Private vars
	const instance = {};
	let form;
	let inputFields;
	let submitBtn;
	let formValidator;

	// Private methods
	const validateForm = (jumpToFirstError = true) => {
		let formValid = formValidator.validate();

		inputFields.forEach((field) => {
			field.classList.remove('has-error');
		});

		if (!formValid) {
			let errors = formValidator.getErrors();
			console.log(errors);

			for (let i = errors.length; i--; ) {
				let inputWrapper = errors[i].input.closest('[data-ref="Form:input"]');
				inputWrapper.classList.add('has-error');

				inputWrapper.querySelector(
					'[data-ref="Input:errorMessage"]'
				).innerHTML = errors[i].errors[0];
			}

			if (jumpToFirstError) {
				let inputWrapper = errors[0].input.closest('[data-ref="Form:input"]');
				let rect = inputWrapper.getBoundingClientRect();
				let scrollY =
					window.scrollY ||
					window.pageYOffset ||
					document.documentElement.scrollTop;
				let viewportHeight = window.innerHeight;
				let upperHalf = {
					top: 20,
					bottom: viewportHeight / 2,
				};

				if (rect.top < upperHalf.top || rect.bottom > upperHalf.bottom) {
					try {
						window.scrollTo({
							top: scrollY + rect.top - upperHalf.top,
							left: 0,
							behavior: 'smooth',
						});
					} catch (e) {
						window.scrollTo(0, scrollY + rect.top - upperHalf.top);
					}
				}
			}
		}

		return formValid;
	};

	const onSubmit = (e) => {
		if (!validateForm()) {
			e.preventDefault();
			return;
		}

		instance.setState('submitting');
		submitBtn.disabled = true;
	};

	const onChange = (e) => {
		if (e.target.closest('[data-ref="Form:input"]')) {
			validateForm(false);
		}
	};

	const bindEvents = () => {
		form.addEventListener('submit', onSubmit);
		instance.el.addEventListener('change', onChange);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('change', onChange);
		form.removeEventListener('submit', onSubmit);
		formValidator.destroy();
	};

	const initialize = (Pristine) => {
		formValidator = new Pristine.default(form);
		bindEvents();
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		form = instance.ref(config.formRef);
		inputFields = instance.ref(config.inputFieldRef);
		submitBtn = instance.ref(config.submitBtn);

		import(/* webpackChunkName: "PRISTINE" */ 'pristinejs/dist/pristine').then(
			initialize
		);

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
