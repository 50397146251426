import contextTrigger from 'lib/util/contextTrigger';
import ModuleManager from 'lib/util/ModuleManager';



import AnimatedBackdrop from '00-base/AnimatedBackdrop/AnimatedBackdrop';



import GradientBackdrop from '00-base/GradientBackdrop/GradientBackdrop';



import Input from '00-base/Input/Input';



import AnimateReveal from '01-widgets/AnimateReveal/AnimateReveal';



import CategoryLinkList from '01-widgets/CategoryLinkList/CategoryLinkList';



import FilterCard from '01-widgets/FilterCard/FilterCard';



import ProfileButton from '01-widgets/ProfileButton/ProfileButton';



import ScrollTopAction from '01-widgets/ScrollTopAction/ScrollTopAction';



import SearchBar from '01-widgets/SearchBar/SearchBar';



import SearchPresets from '01-widgets/SearchPresets/SearchPresets';



import ShareAction from '01-widgets/ShareAction/ShareAction';



import Slider from '01-widgets/Slider/Slider';



import TagList from '01-widgets/TagList/TagList';



import VideoEmbed from '01-widgets/VideoEmbed/VideoEmbed';



import AudioContent from '02-content/AudioContent/AudioContent';



import CardExpander from '02-content/CardExpander/CardExpander';



import CategoriesBar from '02-content/CategoriesBar/CategoriesBar';



import CategoryPageHeader from '02-content/CategoryPageHeader/CategoryPageHeader';



import Filter from '02-content/Filter/Filter';



import Form from '02-content/Form/Form';



import Search from '02-content/Search/Search';



import SearchQuerySlider from '02-content/SearchQuerySlider/SearchQuerySlider';



import CookieBanner from '03-global/CookieBanner/CookieBanner';



import Header from '03-global/Header/Header';



import MessageBar from '03-global/MessageBar/MessageBar';



export default (function() {
	let time = new Date();


	contextTrigger.add('[data-js="AnimatedBackdrop"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(AnimatedBackdrop));
	});


	contextTrigger.add('[data-js="GradientBackdrop"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(GradientBackdrop));
	});


	contextTrigger.add('[data-js="Input"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Input));
	});


	contextTrigger.add('[data-animate]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(AnimateReveal));
	});


	contextTrigger.add('[data-js="CategoryLinkList"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CategoryLinkList));
	});


	contextTrigger.add('[data-js="FilterCard"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(FilterCard));
	});


	contextTrigger.add('[data-js="ProfileButton"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProfileButton));
	});


	contextTrigger.add('[data-js="ScrollTopAction"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ScrollTopAction));
	});


	contextTrigger.add('[data-js="SearchBar"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SearchBar));
	});


	contextTrigger.add('[data-js="SearchPresets"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SearchPresets));
	});


	contextTrigger.add('[data-js="ShareAction"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ShareAction));
	});


	contextTrigger.add('[data-js="Slider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Slider));
	});


	contextTrigger.add('[data-js="TagList"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(TagList));
	});


	contextTrigger.add('[data-js="VideoEmbed"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(VideoEmbed));
	});


	contextTrigger.add('[data-js="AudioContent"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(AudioContent));
	});


	contextTrigger.add('[data-js="CardExpander"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CardExpander));
	});


	contextTrigger.add('[data-js="CategoriesBar"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CategoriesBar));
	});


	contextTrigger.add('[data-js="CategoryPageHeader"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CategoryPageHeader));
	});


	contextTrigger.add('[data-js="Filter"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Filter));
	});


	contextTrigger.add('[data-js="Form"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Form));
	});


	contextTrigger.add('[data-js="Search"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Search));
	});


	contextTrigger.add('[data-js="SearchQuerySlider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SearchQuerySlider));
	});


	contextTrigger.add('[data-js="CookieBanner"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CookieBanner));
	});


	contextTrigger.add('[data-js="Header"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Header));
	});


	contextTrigger.add('[data-js="MessageBar"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(MessageBar));
	});



	contextTrigger.validate(document.body);

	console.log('Selecting components took: ', new Date() - time, 'ms');
}());
