/**
 * CategoriesBar
 *
 * @selector [data-js="CategoriesBar"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { debounce } from 'throttle-debounce';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function CategoriesBar() {
	// Private vars
	const instance = {};
	let lineHeight = 18;

	//TODO: Ensure that rightmost tag is always cropped by viewport

	// Private methods
	const getScrollLineHeight = () => {
		const el = document.createElement('div');
		el.style.fontSize = 'initial';
		el.style.display = 'none';
		document.body.appendChild(el);
		const lh = parseFloat(window.getComputedStyle(el).lineHeight);
		document.body.removeChild(el);
		return lh;
	};

	const handleWheelScroll = (e) => {
		// Don't do anything if already scrolling horizontally
		if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		instance.scrollContainer.scrollLeft +=
			e.deltaMode === 1 ? e.deltaY * (e.deltaMode * lineHeight) : e.deltaY;
	};

	const handleScrollPrev = () => {
		setScroll(-500);
	};

	const handleScrollNext = () => {
		setScroll(500);
	};

	const setScroll = (offset) => {
		if (typeof instance.scrollContainer.scrollBy === 'function')
			instance.scrollContainer.scrollBy({
				left: offset,
				behavior: 'smooth',
			});
	};

	const checkBtnVisiblity = () => {
		const scrolledLeft = instance.scrollContainer.scrollLeft !== 0;

		instance.prev.classList.toggle('is-hidden', !scrolledLeft);
		instance.prev.classList.toggle('is-visible', scrolledLeft);

		const scrolledRight =
			Math.ceil(
				instance.scrollContainer.getBoundingClientRect().width +
					instance.scrollContainer.scrollLeft
			) >=
			instance.scrollContainer.scrollWidth - 10; // -10 to ignore to close scrolled by cases

		instance.next.classList.toggle('is-hidden', scrolledRight);
		instance.next.classList.toggle('is-visible', !scrolledRight);
	};

	const bindEvents = () => {
		instance.scrollContainer.addEventListener('wheel', handleWheelScroll);
		instance.scrollContainer.addEventListener(
			'scroll',
			debounce(200, checkBtnVisiblity)
		);
		instance.prev.addEventListener('click', handleScrollPrev);
		instance.next.addEventListener('click', handleScrollNext);
	};

	const unbindEvents = () => {
		instance.scrollContainer.removeEventListener('wheel', handleWheelScroll);
		instance.scrollContainer.removeEventListener(
			'scroll',
			debounce(200, checkBtnVisiblity)
		);
		instance.prev.removeEventListener('click', handleScrollPrev);
		instance.next.removeEventListener('click', handleScrollNext);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		instance.scrollContainer = instance.ref('scrollcontainer');
		instance.prev = instance.ref('prev');
		instance.next = instance.ref('next');

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		lineHeight = getScrollLineHeight();
		bindEvents();
		checkBtnVisiblity();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
