/**
 * MessageBar
 *
 * @selector [data-js="MessageBar"]
 * @enabled true
 */
import { base } from 'app/util/base';
import Cookies from 'js-cookie';

const defaults = {
	cookieExpiration: 0,
	cookieId: null,
};

const config = {
	optionsAttr: 'data-options',
};

export default function MessageBar() {
	// Private vars
	const instance = {};
	let closeButton;

	let settings = {};

	// Private methods
	const bindEvents = () => {
		if (closeButton) {
			closeButton.addEventListener('click', handleCloseClick);
		}
	};

	const unbindEvents = () => {
		if (closeButton) {
			closeButton.removeEventListener('click', handleCloseClick);
		}
	};

	const checkExpiration = () => {
		if (!Cookies.get(settings.cookieId)) {
			instance.el.classList.add('in');
		}
	};

	const handleCloseClick = () => {
		const exp = settings.cookieExpiration;

		if (exp > 0) {
			Cookies.set(settings.cookieId, true, { expires: exp });
		} else {
			Cookies.set(settings.cookieId, true);
		}
		instance.el.classList.remove('in');
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		closeButton = instance.ref('close');

		bindEvents();

		setTimeout(() => {
			checkExpiration();
		}, 100);

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
