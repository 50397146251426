/**
 * GradientBackdrop
 *
 * @selector [data-js="GradientBackdrop"]
 * @enabled true
 */
import glu from 'gl-util';
import fastdom from 'fastdom';
import seed from 'seed-random';
import createSpring from 'lemonade-spring';

import { base } from 'app/util/base';
import { resizeeventsManager } from 'app/util/events-manager';
import vertexShader from './gl/gradient-vertex.glsl';
import fragmentShader from './gl/gradient-fragment.glsl';

const defaults = {
	color1: '#FF6666',
	color2: '#FF3838',
	color3: '#578799',
	color4: '#345E73',
	rotation: 0,
	offsetH: 0,
	offsetV: 0,
	scaleX: 1.4,
	scaleY: 1,
	seed: '50',
	mouseTarget: null,
};

const config = {
	optionsAttr: 'data-options',
};

const hexToFloat = (hex, alpha) => {
	let i = parseInt(hex[0] === '#' ? hex.substr(1) : hex, 16);
	let r = (i >> 16) & 0xff;
	let g = (i >> 8) & 0xff;
	let b = i & 0xff;
	let rgb = [r / 255, g / 255, b / 255];
	if (alpha !== undefined) {
		rgb.splice(3, 0, alpha);
	}
	return rgb;
};

export default function GradientBackdrop() {
	// Private vars
	const instance = {};
	let settings = {};
	let canvas,
		gl,
		prog,
		random,
		mouseTarget,
		pendingRepaint = false;
	let frameHandle,
		repaintHandle,
		loopMode = false,
		coords = [0, 0];

	let resizeInfo = {
		width: 0,
		height: 0,
		top: 0,
		left: 0,
		ppi: 0,
		mustResize: false,
	};

	const stopLoop = () => {
		if (frameHandle) {
			window.cancelAnimationFrame(frameHandle);
			frameHandle = null;
			loopMode = false;
		}
	};

	let spring = createSpring(coords, {
		mass: 1,
		stiffness: 0.04,
		damping: 0.9,
		precision: 0.1,
		onComplete: stopLoop,
	});

	const resizeHandler = () => {
		const bounds = canvas.getBoundingClientRect();
		resizeInfo.ppi = 1; //Math.min(2, window.devicePixelRatio || 1);
		resizeInfo.width = bounds.width;
		resizeInfo.height = bounds.height;
		resizeInfo.left = bounds.left + window.pageXOffset;
		resizeInfo.top = bounds.top + window.pageYOffset;
		resizeInfo.mustResize = true;
		repaint();
	};

	const handleMouseMove = (evt) => {
		spring.target([evt.pageX - resizeInfo.left, evt.pageY - resizeInfo.top]);

		if (!frameHandle) {
			loopMode = true;
			frameHandle = window.requestAnimationFrame(update);
		}
	};

	// Private methods
	const bindEvents = () => {
		mouseTarget.addEventListener('mousemove', handleMouseMove);
		resizeeventsManager.add(resizeHandler);
		instance.el.addEventListener('updateShader', updateShader);
	};

	const unbindEvents = () => {
		mouseTarget.removeEventListener('mousemove', handleMouseMove);
		resizeeventsManager.remove(resizeHandler);
		instance.el.removeEventListener('updateShader', updateShader);
	};

	const repaint = () => {
		if (!pendingRepaint && !loopMode) {
			pendingRepaint = true;
			repaintHandle = window.requestAnimationFrame(update);
		}
	};

	const update = () => {
		if (resizeInfo.mustResize) {
			canvas.width = resizeInfo.width * resizeInfo.ppi;
			canvas.height = resizeInfo.height * resizeInfo.ppi;
			gl.viewport(0, 0, canvas.width, canvas.height);
			resizeInfo.mustResize = false;
		}
		spring.update();
		glu.uniform(prog, 'mouse', [
			coords[0] / resizeInfo.width,
			1 - coords[1] / resizeInfo.height,
		]);
		gl.drawArrays(gl.TRIANGLES, 0, 6);
		if (loopMode) {
			frameHandle = window.requestAnimationFrame(update);
		}
		pendingRepaint = false;
	};

	const updateShader = (params = {}) => {
		const cfg = params instanceof Event ? params.detail : params;
		glu.uniform(prog, 'offset', [
			typeof cfg.offsetH === 'number' ? cfg.offsetH : settings.offsetH,
			typeof cfg.offsetV === 'number' ? cfg.offsetV : settings.offsetV,
		]);
		glu.uniform(prog, 'scale', [
			cfg.scaleX || settings.scaleX,
			cfg.scaleY || settings.scaleY,
		]);
		glu.uniform(
			prog,
			'rotation',
			typeof cfg.rotation === 'number' ? cfg.rotation : settings.rotation
		);
		glu.uniform(prog, 'color1', hexToFloat(cfg.color1 || settings.color1, 1));
		glu.uniform(prog, 'color2', hexToFloat(cfg.color2 || settings.color2, 1));
		glu.uniform(prog, 'color3', hexToFloat(cfg.color3 || settings.color3, 1));
		glu.uniform(prog, 'color4', hexToFloat(cfg.color4 || settings.color4, 1));
		repaint();
	};

	const createCanvas = () => {
		canvas = document.createElement('canvas');
		//canvas = instance.el;
		instance.el.appendChild(canvas);
		gl = glu.context(canvas);

		fastdom.measure(resizeHandler);
		fastdom.mutate(() => {
			prog = glu.program(gl, vertexShader, fragmentShader);
			//glu.program(gl, prog);
			glu.attribute(gl, 'position', [0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1], prog);
			glu.uniform(prog, 'resolution', [resizeInfo.width, resizeInfo.height]);
			glu.uniform(prog, 'mouse', [0.5, 0.5]);

			updateShader(settings);
			/*
			glu.uniform(prog, 'offsetH', random() * Math.PI * 2);
			glu.uniform(prog, 'offsetV', random() * Math.PI * 2);
			glu.uniform(prog, 'radius', 200);
			glu.uniform(prog, 'radius2', 300);

			glu.uniform(prog, 'color1', hexToFloat(settings.color1, 1));
			glu.uniform(prog, 'color2', hexToFloat(settings.color2, 1));
			glu.uniform(prog, 'color3', hexToFloat(settings.color3, 1));
			glu.uniform(prog, 'color4', hexToFloat(settings.color4, 1));
			 */
			gl.drawArrays(gl.TRIANGLES, 0, 6);
			repaint();
			bindEvents();

			if (document.fonts) {
				document.fonts.ready.then(repaint);
			}
		});
	};

	const destroyCanvas = () => {
		fastdom.mutate(() => {
			if (typeof canvas !== 'undefined') {
				unbindEvents();

				window.cancelAnimationFrame(frameHandle);
				window.cancelAnimationFrame(repaintHandle);
				frameHandle = null;
				pendingRepaint = false;
				loopMode = false;

				instance.el.removeChild(canvas);
				canvas = null;
				gl = null;

				// TODO: properly destroy canvas context
			}
		});
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		random = seed(settings.seed);
		settings.offsetH = random() * 2 - 1;
		settings.offsetV = random() * 2 - 1;
		settings.rotation = (random() - 0.5) * Math.PI;
		mouseTarget = instance.el.closest(settings.mouseTarget) || instance.el;

		let observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					createCanvas();
				} else {
					destroyCanvas();
				}
			});
		});

		observer.observe(instance.el);

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
