/**
 * Category Link List
 *
 * @selector [data-js="CategoryLinkList"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function TagList() {
	// Private vars
	const instance = {};
	let settings = {};
	let categories = null;
	let trigger = null;

	// Private methods
	const bindEvents = () => {
		trigger.addEventListener('click', handleTriggerClick);
	};

	const unbindEvents = () => {
		trigger.removeEventListener('click', handleTriggerClick);
	};

	const handleTriggerClick = () => {
		expand();
	};

	const expand = () => {
		categories.forEach((category) => {
			category.classList.remove(settings.categoriesClassHidden);
		});
		trigger.classList.add(settings.categoriesClassHidden);
	};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		categories = instance.el.querySelectorAll(`.${settings.categoriesClass}`);
		trigger = instance.el.querySelector(`.${settings.triggerClass}`);

		if (!categories.length || !trigger) {
			return;
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
