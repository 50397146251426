/**
 * ProfileButton
 *
 * @selector [data-js="ProfileButton"]
 * @enabled true
 */
import { base } from 'app/util/base';
import messageBus from 'app/util/message-bus';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function ProfileButton() {
	// Private vars
	const instance = {};
	let closeButton, inputToggler;

	const closeClickHandler = () => {
		inputToggler.checked = false;
		inputToggle();
	};

	const inputToggle = () => {
		messageBus.emit('ProfileButton:toggle', inputToggler.checked);
		if (inputToggler.checked) {
			document.body.addEventListener('click', bodyClickHandler);
		}
	};

	const bodyClickHandler = (evt) => {
		if (!evt.target.closest('[data-js="ProfileButton"]')) {
			document.body.removeEventListener('click', bodyClickHandler);
			closeClickHandler();
		}
	};

	// Private methods
	const bindEvents = () => {
		if (closeButton) {
			closeButton.addEventListener('click', closeClickHandler);
		}

		if (inputToggler) {
			inputToggler.addEventListener('change', inputToggle);
		}
	};

	const unbindEvents = () => {
		if (closeButton) {
			closeButton.removeEventListener('click', closeClickHandler);
		}

		if (inputToggler) {
			inputToggler.removeEventListener('change', inputToggle);
		}

		// Just in case the event is still bound
		document.body.removeEventListener('click', bodyClickHandler);
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		closeButton = instance.ref('close');
		inputToggler = instance.ref('toggle');
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
