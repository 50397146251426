import { resizeeventsManager } from './events-manager';
import fastdom from 'fastdom';

class RevealHandler {
	constructor() {
		this.elems = [];
		this.bound = false;
		this.purgeTimeout = 0;

		this.purge = this.purge.bind(this);
		this.measure = this.measure.bind(this);
		this.measureSingle = this.measureSingle.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.measureScroll = this.measureScroll.bind(this);
		this.performScroll = this.performScroll.bind(this);

		if (document.fonts) {
			document.fonts.ready.then(this.measure);
		}

		window.addEventListener('load', this.measure);
	}

	bindEvents() {
		if (this.bound) {
			return;
		}

		resizeeventsManager.add(this.measure);
		if (window.HV.supportsPassive) {
			window.addEventListener('scroll', this.handleScroll, { passive: true });
		} else {
			window.addEventListener('scroll', this.handleScroll);
		}
		//scrolleventsManager.add(this.handleScroll);
		this.bound = true;
	}

	unbindEvents() {
		if (!this.bound) {
			return;
		}

		resizeeventsManager.remove(this.measure);
		//scrolleventsManager.remove(this.handleScroll);
		if (window.HV.supportsPassive) {
			window.removeEventListener('scroll', this.handleScroll, {
				passive: true,
			});
		} else {
			window.removeEventListener('scroll', this.handleScroll);
		}
		this.bound = false;
	}

	purge() {
		for (let i = this.elems.length - 1; i >= 0; i--) {
			if (this.elems[i].purge) {
				this.elems.splice(i, 1);
			}
		}

		if (this.elems.length === 0) {
			this.unbindEvents();
		}

		this.purgeTimeout = 0;
	}

	register(el, options = {}) {
		let trigger = {
			type: 'vh',
			value: 1,
			once: !!options.once,
			top: Number.MAX_SAFE_INTEGER,
			className: options.className || 'animate-reveal',
			staggerDelay: options.staggerDelay || 0,
			staggerOffset: options.staggerOffset || 0,
		};

		if (options.trigger) {
			if (typeof options.trigger === 'number') {
				trigger.type = options.trigger < 0 ? 'bottom' : 'top';
				trigger.value = Math.abs(options.trigger);
			} else {
				let matches = options.trigger.match(/^(\d+)%$/, options.trigger);
				if (matches) {
					trigger.value = parseInt(matches[1]) / 100;
				}
			}
		}

		this.elems.push({
			el,
			trigger,
			debug: !!options.debug,
			purge: false,
		});

		this.measureSingle(this.elems[this.elems.length - 1]);

		if (this.elems.length > 0) {
			this.bindEvents();
		}
	}

	unregister(el) {
		for (let i = 0, len = this.elems.length; i < len; i++) {
			if (this.elems[i].el === el) {
				this.elems[i].purge = true;
			}
		}

		if (!this.purgeTimeout) {
			this.purgeTimeout = window.requestIdleCallback(this.purge);
		}
	}

	measureSingle(obj) {
		if (obj.purge) {
			return;
		}

		let vw;
		fastdom.measure(() => {
			const rect = obj.el.getBoundingClientRect();
			const vh = window.innerHeight;
			vw = window.innerWidth;
			obj.left = rect.left + window.pageXOffset;
			obj.top = rect.top + window.pageYOffset;
			obj.bottom = obj.top + rect.height;
			obj.rect = rect;

			switch (obj.trigger.type) {
				case 'vh':
					obj.trigger.top = obj.top + (vh - vh * obj.trigger.value);
					break;
				case 'bottom':
					obj.trigger.top = obj.top + obj.trigger.value;
					break;
				case 'top':
					obj.trigger.top = obj.top + (vh - obj.trigger.value);
					break;
			}

			obj.trigger.top += (obj.trigger.staggerOffset * obj.left) / vw;
		});

		if (obj.trigger.staggerDelay) {
			fastdom.mutate(() => {
				if (obj.debug) {
					if (!obj.debugEl) {
						const el = document.createElement('div');
						el.classList.add('animate-debug');
						document.body.appendChild(el);
						obj.debugEl = el;
					}

					obj.debugEl.style.width = `${obj.rect.width}px`;
					obj.debugEl.style.height = `${obj.rect.height}px`;
					obj.debugEl.style.top = `${obj.trigger.top}px`;
					obj.debugEl.style.left = `${obj.left}px`;
				}
				obj.el.style.setProperty(
					'--staggerDelay',
					`${Math.round((obj.trigger.staggerDelay * obj.left) / vw)}ms`
				);
			});
		}
	}

	measure() {
		this.elems.forEach(this.measureSingle);
		this.handleScroll();
	}

	measureScroll() {
		const vh = window.innerHeight;
		this.scrollBottom = window.pageYOffset + vh;
	}

	performScroll() {
		let mustPurge = false;
		for (let i = 0, len = this.elems.length; i < len; i++) {
			const obj = this.elems[i];
			if (obj.purge) {
				continue;
			}

			if (obj.trigger.top <= this.scrollBottom) {
				obj.el.classList.add(obj.trigger.className);
				if (obj.trigger.once) {
					obj.purge = mustPurge = true;
				}
			} else if (obj.top > this.scrollBottom) {
				obj.el.classList.remove(obj.trigger.className);
			}
		}

		if (mustPurge && !this.purgeTimeout) {
			this.purgeTimeout = window.requestIdleCallback(this.purge);
		}
	}

	handleScroll() {
		fastdom.measure(this.measureScroll);
		fastdom.mutate(this.performScroll);
	}
}

export const revealHandler = new RevealHandler();
