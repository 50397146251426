/**
 * SearchQuerySlider
 *
 * @selector [data-js="SearchQuerySlider"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

export default function SearchQuerySlider() {
	// Private vars
	const instance = {};
	let sliderNode, swiper, prev, next;

	// Private methods
	const handleSliderReady = (evt) => {
		swiper = evt.detail;
		swiper.on('slideChange', updateButtons);
		updateButtons();
	};

	const updateButtons = () => {
		if (swiper && swiper.isBeginning) {
			prev.classList.add('disabled');
		} else {
			prev.classList.remove('disabled');
		}

		if (swiper && swiper.isEnd) {
			next.classList.add('disabled');
		} else {
			next.classList.remove('disabled');
		}
	};

	const handleNext = () => {
		if (swiper) {
			let offset =
				typeof swiper.params.slidesPerView === 'number'
					? swiper.params.slidesPerView
					: 1;
			swiper.slideTo(swiper.activeIndex + offset);
		}
	};

	const handlePrev = () => {
		if (swiper) {
			let offset =
				typeof swiper.params.slidesPerView === 'number'
					? swiper.params.slidesPerView
					: 1;
			swiper.slideTo(swiper.activeIndex - offset);
		}
	};

	const bindEvents = () => {
		sliderNode.addEventListener('swiperInit', handleSliderReady);
		prev.addEventListener('click', handlePrev);
		next.addEventListener('click', handleNext);
	};

	const unbindEvents = () => {
		sliderNode.removeEventListener('swiperInit', handleSliderReady);
		prev.removeEventListener('click', handlePrev);
		next.removeEventListener('click', handleNext);
		if (swiper) {
			swiper.off('slideChange', updateButtons);
		}
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);
		sliderNode = instance.ref('cardSlider');
		prev = instance.ref('prev');
		next = instance.ref('next');
		updateButtons();

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
		swiper = null;
		sliderNode = null;
		next = null;
		prev = null;
	};

	return instance;
}
